import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import PageSection from "./components/PageSection/PageSection";
import DownloadSection from "./components/DownloadSection/DownloadSection";
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import GuidePageUpdate from "./components/GuildPageUpdate/GuildPageUpdate";
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Tips from './components/Tips/Tips';
import GuildDetail from './components/GuildDetails/GuildDetail';
import Solutions from './components/Solutions/Solution';

function Layout() {
    return (
        <div className="App">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

function Home() {
    return (
        <>
            <FeaturesSection />
            <PageSection />
            <DownloadSection />
        </>
    );
}

const usePageTitle = () => {
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        let title = 'H.Work';

        switch (path) {
            case '/':
                title = 'Download - H.Work';
                break;
            case '/guide-update':
                title = 'Guide Update - H.Work';
                break;
            case '/privacy-policy':
                title = 'Privacy Policy - H.Work';
                break;
            case '/tips':
                title = 'Tips - H.Work';
                break;
            case '/guide-detail':
                title = 'Guide Detail - H.Work';
                break;
            case '/solution':
                title = 'Solutions - H.Work';
                break;
            default:
                title = 'H.Work';
                break;
        }

        document.title = title;
    }, [location]);
};

function App() {
    return (
        <Router>
            <PageTitleUpdater />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="guide-update" element={<GuidePageUpdate />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="tips" element={<Tips />} />
                    <Route path="guide-detail" element={<GuildDetail />} />
                    <Route path="solution" element={<Solutions />} />
                </Route>
            </Routes>
        </Router>
    );
}

function PageTitleUpdater() {
    usePageTitle();
    return null;
}

export default App;