import React from 'react';
import './Header.css'; // Assuming you will style using a separate CSS file
import logo from '../../images/logo.png';
import { NavLink } from 'react-router-dom';

const Header = () => {
    
    return (
        <header className="header">
            <div className="logo" onClick={() => window.location.href = '/'}>
                <img src={logo} alt="Logo"/>
                <span>H.Work</span>
            </div>
            <nav className="nav">
                <ul>
                    <li>
                        <NavLink to="/guide-update">Hướng dẫn cài đặt</NavLink>
                    </li>
                    <li>
                        <NavLink to="/privacy-policy">Chính sách</NavLink>
                    </li>
                    <li>
                        <NavLink to="/tips">Mẹo sử dụng</NavLink>
                    </li>
                    <li>
                        <NavLink to ="/guide-detail">Hướng dẫn chi tiết</NavLink>
                    </li>
                    <li>
                        <NavLink to ="/solution">Giải pháp</NavLink>
                    </li>
                    <li>
                        <a href="#download-section" onClick={() => window.location.href = '/#download-section'}>Tải
                            xuống</a>
                    </li>
                </ul>
            </nav>
            <div className="auth-buttons">
                <button className="get-started" onClick={() => window.location.href = 'https://chat.htigroup.vn'}>
                    Bắt Đầu
                </button>
            </div>
        </header>
    );
};

export default Header;