import React from 'react';
import './FeaturesSection.css'; // Assuming you will style using a separate CSS file

import Videocall from '../../images/video_call.png';
import Group_chat_258_236 from '../../images/Box_chat.png';
import multi from '../../images/media.png';
import Legcat from '../../images/sticker.png';


const FeaturesSection = () => {
    return (
        <div class="features-container">
            <h2 class="title">Ứng dụng nhắn tin</h2>
            <p class="description">Đa dạng tính năng nhắn tin, gọi điện, video call tiện lợi và dễ dàng, đặc biệt hiệu quả trong việc làm việc nhóm.</p>
            <div class="features">
                <div class="feature">
                    <img className="img-fluid" src={Videocall}
                         alt="Cuộc gọi thoại và video"/>
                    <h3>Cuộc gọi thoại và video</h3>
                    <p>H.Work cung cấp tính năng gọi thoại và video chất lượng, cho phép bạn kết nối với đồng nghiệp bất cứ khi nào cần thiết.</p>
                </div>
                <div class="feature">
                    <img className="img-fluid" src={Group_chat_258_236}
                         alt="Trò chuyện nhóm &amp; cuộc gọi"/>
                    <h3>Trò chuyện nhóm & cuộc gọi</h3>
                    <p>H.Work cho phép bạn tạo các phòng chat nhóm để trao đổi thông tin với nhiều người cùng lúc, thuận tiện cho việc thảo luận nhóm, chia sẻ ý kiến và cập nhật tiến độ công việc.</p>
                </div>
                <div class="feature">
                    <img className="img-fluid" src={Legcat}
                         alt="Sticker &amp; GIF biểu cảm"/>
                    <h3>Sticker & GIF biểu cảm</h3>
                    <p> H.Work mang đến bộ sưu tập sticker và GIF đa dạng, giúp bạn thể hiện cảm xúc, tạo sự vui nhộn và tăng cường sự tương tác trong các cuộc trò chuyện.</p>
                </div>
                <div class="feature">
                    <img className="img-fluid" src={multi}
                         alt="Trên thiết bị di động, máy tính để bàn và máy tính bảng "/>
                    <h3>Đồng bộ hoá trên mọi thiết bị</h3>
                    <p>Giúp bạn làm việc mọi lúc, mọi nơi với đồng bộ hóa trên mọi thiết bị tất cả đều được cập nhật theo thời gian thực.</p>
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection ;