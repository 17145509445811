import React from 'react';
import './Tips.css';

const Tips = () => {

    return (
        <div className="tips-guild">
            <br/>
            <h1>
                <strong>Tips Sử dụng phần mềm H.Work</strong>
            </h1>
            <br/>
            <h2>#Hint 1:</h2>
            <br/>
            <p>Tạo Workspace cho team hoặc dự án.</p>
            <p>Trong dự án có room (hay topic) là các khối chức năng hoặc dự án.</p>
            <p>
                <strong>*Nên assign thêm quyền admin và mods để người khác đồng hành quản lý.</strong>
            </p>
            <p>
                <strong>*Tạo room có mode public ra ngoài, có mod cho người trong workspace và có cả mod room riêng cho
                    invite person (có thể là trao đổi riêng và hạn chế người trong team tham gia).</strong>
            </p>'
            <br/>
            <h2>#Hint 2:</h2>
            <br/>
            <p>Cách để gủi tin nhắn kèm hiệu ứng trên H.Work. Cú pháp <strong> /hiệu_ứng nội_dung_tin nhắn.</strong>
            </p>
            <p>Danh sách các hiệu ứng như sau: </p>
            <p>
                <strong>/confetti</strong> &lt;message&gt; chat_effects|confetti_description
            </p>
            <p>
                <strong>/fireworks</strong> &lt;message&gt; chat_effects|fireworks_description
            </p>
            <p>
                <strong>/rainfall</strong> &lt;message&gt; chat_effects|rainfall_description
            </p>
            <p>
                <strong>/snowfall</strong> &lt;message&gt; chat_effects|snowfall_description
            </p>
            <p>
                <strong>/spaceinvaders</strong> &lt;message&gt; chat_effects|spaceinvaders_description
            </p>
            <p>
                <strong>/hearts</strong> &lt;message&gt; chat_effects|hearts_description
            </p>
            <br/>
            <h2>#Hint 3:</h2>
            <br/>
            <p>Set nickname cho bạn:</p>
            <p>
                <strong>Cách 1: set nickname cho phòng nhắn tin hiện tại.</strong>
            </p>
            <p>Cú pháp: /myroomnick &lt;nick_name&gt; : Apply với phòng chat hiện tại, các phòng chat khác không thay
                đổi.</p>
            <p>
                <strong>Cách 2: Thay đổi nickname của bạn</strong>.
            </p>
            <p>Cú pháp: /nickname &lt;nick_name&gt;: Apply với toàn bộ các room, Space.</p>
            <br/>
            <h2>#Hint 4 Quan Trọng:</h2>
            <br/>
            <p>
                <strong>Tips sử dụng H.Work:</strong>
            </p>
            <p>Ít nhất 1 phiên làm việc phải luôn luôn được mở ( tốt nhất là trên điện thoại ). Điều này sẽ giúp anh chị
                luôn nhận được key giải mã tin nhắn trong trường hợp các thiết bị khác gặp sự cố kết nối.</p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>
            <p>
                <br/>
            </p>

        </div>
    );
};

export default Tips;