import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>
                <strong>Privacy policy for H.Work.</strong>
            </h1>
            <br/>
            <h2>
                <strong>1. Introduction</strong>
            </h2>
            <br/>
            <h3>
                <strong>1.1 English, Not Legalese</strong>
            </h3>
            <p>Most Terms of Use and Privacy Policy documents are unreadable. They are written by lawyers and for
                lawyers, and in our opinion are not very effective.</p>
            <p>Privacy is important, and we want you to understand the issues involved. For that reason we decided to
                use plain H.Work as much as possible, to make our terms as clear as possible. Some sections still have
                room for improvement - we plan to tackle these over time.</p>
            <p>Where you read 'H.Work', ‘H.Work&nbsp;Matrix Services’, ‘HMS’, ‘the&nbsp;H.Work&nbsp;app store’ it refers
                to all services made available at <a href="https://element.io/" target="_blank">
                    <u>&nbsp;</u>
                </a>
                <a href="https://chat.htigroup.vn/" target="_blank">
                    <u>https://chat.htigroup.vn</u>
                </a>&nbsp;for:
            </p>
            <ul>
                <li>The&nbsp;H.Work&nbsp;chat app, an open source Matrix client which you can use to connect to any
                    server that implements the Matrix protocol;
                </li>
                <li>The Gitter developer communication app;</li>
                <li>The purchase, provisioning, configuration, monitoring and management of hosted homeservers and
                    associated services via&nbsp;H.Work&nbsp;Matrix Services (HMS), or the&nbsp;H.Work&nbsp;app store.
                </li>
            </ul>
            <p>Where you read 'homeserver', 'homeservers' or 'the Homeserver', it refers to the services configured
                within&nbsp;H.Work&nbsp;which store the user account and personal conversation history, provide
                additional functionality such as bots and bridges, and (where enabled by the Customer) communicate via
                the open Matrix decentralised communication protocol with the public Matrix Network.</p>
            <p>H.Work&nbsp;is the Data Controller for your data. We can be contacted as per the details below:</p>
            <p>Email: htsc.cloud@gmail.com</p>
            <p>Postal address:</p>
            <p>H.Work</p>
            <p>16F - VP2</p>
            <p>Sun Square Building</p>
            <p>21 Le Duc Tho Street</p>
            <p>Nam Tu Liem District, Hanoi</p>
            <p>Vietnam</p>
            <p>Should you have other questions or concerns about this document, please send us an email at
                htsc.cloud@gmail.com.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>1.2. Scope of This Document</strong>
            </h3>
            <br/>
            <p>This document explains how we process personal data, as it relates to:</p>
            <ul>
                <li>H.Work&nbsp;chat app users: H.Work chat app users use&nbsp;H.Work&nbsp;to connect to any server that
                    implements the Matrix Protocol.
                </li>
                <li>&nbsp;</li>
                <li>Gitter app users: Gitter.im is now a part of&nbsp;H.Work, and will soon be bridged to the Matrix
                    protocol, allowing its users to talk to other Matrix users.
                </li>
                <li>H.Work&nbsp;Customers:&nbsp;H.Work&nbsp;Customers use&nbsp;H.Work&nbsp;Matrix Services (HMS) to
                    provision and manage hosted homeservers. Apart from where otherwise noted, this document does not
                    address data protection issues relating to the messaging and file data submitted by Users to the
                    hosted homeserver instances, as this is the legal responsibility of the Customer. For general Terms
                    of Use for Homeserver users, please see:&nbsp; <a
                        href="https://static.element.io/legal/online-EULA.pdf" target="_blank">
                        <u>EULA</u>
                    </a>.
                </li>
            </ul>
            <p>This document does not cover:</p>
            <ul>
                <li>Your relationship with the Matrix server if it isn't provided by HMS: Your account details and
                    preferences, messages, files and any other data you share via the Matrix protocol are the
                    responsibility of the Matrix server, and it is the provider of that server's job to make sure your
                    data is handled appropriately and that you are well informed.
                </li>
                <li>&nbsp;</li>
                <li>Your relationship with identity servers: you might choose to use an identity server, to allow other
                    Matrix users to discover you via the&nbsp;H.Work&nbsp;app. This is optional and requires your
                    explicit consent to discover users from your personal contacts. For the identity server made
                    available by us, please see the&nbsp; <a href="https://element.io/is-privacy-notice"
                                                             target="_blank">
                        <u>Identity Server Privacy Notice</u>
                    </a>.
                </li>
            </ul>
            <p>If you are using the matrix.org server provided by the Matrix Foundation, you can find the relevant
                Privacy Notice and Terms and Conditions here:</p>
            <ul>
                <li>Matrix.org Privacy Notice -&nbsp; <a href="https://matrix.org/docs/guides/privacy_notice.html"
                                                         target="_blank">
                    <u>https://matrix.org/docs/guides/privacy_notice.html</u>
                </a>
                </li>
                <li>
                    <strong>Matrix.org Terms and Conditions -&nbsp;</strong>
                    <a href="https://matrix.org/docs/guides/terms_and_conditions.html" target="_blank">
                        <u>https://matrix.org/docs/guides/terms_and_conditions.html</u>
                    </a>
                </li>
                <li>
                    <strong>Matrix.org Code of Conduct -&nbsp;</strong>
                    <a href="https://matrix.org/docs/guides/code_of_conduct" target="_blank">
                        <u>https://matrix.org/docs/guides/code_of_conduct</u>
                    </a>
                </li>
                <li>
                    <strong>Matrix.org Copyright Notice -&nbsp;</strong>
                    <a href="https://matrix.org/docs/guides/copyright_notice" target="_blank">
                        <u>https://matrix.org/docs/guides/copyright_notice</u>
                    </a>
                </li>
            </ul>
            <br/>
            <h3>
                <strong>1.3. The Customer and the User</strong>
            </h3>
            <br/>
            <p>This document is designed to explain Data Protections issues relating to&nbsp;H.Work&nbsp;Customers and
                Users. Put simply, you're a Customer if you're paying (or otherwise compensating)&nbsp;H.Work&nbsp;to
                provide a dedicated hosted messaging service. If you have an account registered on a homeserver that you
                use to send and receive messages, or use the&nbsp;H.Work&nbsp;chat app to connect any server within the
                Matrix Protocol, you are a User.</p>
            <p>It is possible to be both a Customer and a User, but we encourage you to consider these roles separately
                when thinking about Data Protection concerns.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>1.4. Changes to This Document</strong>
            </h3>
            <br/>
            <p>Over time we may make changes to this document. If we make a material change we will provide the Customer
                with reasonable notice prior to the change. We will set forth the date upon which the changes will
                become effective; any use of&nbsp;H.Work&nbsp;by the Customer, or any use of a hosted homeserver from
                HMS by a User will constitute the Customer's acceptance of these changes.</p>
            <p>Your access and use of&nbsp;H.Work&nbsp;is always subject to the most current version of this
                document.</p>
            <br/>
            <h2>
                <strong>2. Access to Your Data</strong>
            </h2>
            <br/>
            <h3>
                <strong>2.1. What is the legal basis for processing my data and how does this affect my rights under GDP
                    (General Data Protection Regulation)?</strong>
            </h3>
            <br/>
            <p>
                <strong>2.1.1 Legal Basis for Processing</strong>
            </p>
            <br/>
            <p>
                <strong>H.Work&nbsp;has different legal basis for processing, based on which product you are
                    using:</strong>
            </p>
            <ul>
                <li>H.Work&nbsp;chat app users: we collect your IP address when you request access to
                    the&nbsp;H.Work&nbsp;client from our web server. This data is collected under&nbsp; <a
                        href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/legitimate-interests/when-can-we-rely-on-legitimate-interests/"
                        target="_blank">
                        <u>Legitimate Interest</u>
                    </a>, to support operational maintenance and to protect against malicious actions against our
                    infrastructure.
                </li>
                <li>&nbsp;</li>
                <li>Gitter app users: we collect the details of whatever account you use to sign-up to Gitter, via OAuth
                    (this could be GitLab/GitHub/Twitter). This data is collected under&nbsp; <a
                        href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/legitimate-interests/when-can-we-rely-on-legitimate-interests/"
                        target="_blank">
                        <u>Legitimate Interest</u>
                    </a>, to allow you to use the service.
                </li>
                <li>H.Work&nbsp;customers: your data is processed under&nbsp; <a
                    href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/contract/"
                    target="_blank">
                    <u>Performance of Contract</u>
                </a>. This means that we process your data only as necessary to meet our contractual obligations to you,
                    or to engage with you to do something before entering into a contract (such as providing a quote);
                </li>
            </ul>
            <br/>
            <p>
                <strong>2.1.2. Data Ownership - Messaging and File data within hosted homeservers</strong>
            </p>
            <br/>
            <p>
                <strong>The Customer can use H.Work Matrix Services to provision and manage hosted Matrix homeservers.
                    The Customer owns and controls all messages and files submitted to their homeserver by User accounts
                    registered natively on their homeserver. This ownership does not extend to messages and files
                    submitted over federation or bridging.</strong>
            </p>
            <p>
                <strong>This means that, in addition to the usual data access controls defined by the Matrix protocol,
                    all unencrypted messages and files can be accessed by the Customer, and that access is retained even
                    if no User account within the system retains access to the data.</strong>
            </p>
            <p>
                <strong>‍</strong>
            </p>
            <p>
                <strong>2.1.3. Your rights as Data Subject</strong>
            </p>
            <br/>
            <p>
                <strong>You have rights in relation to the personal data we hold about you. Some of these only apply in
                    certain circumstances. Some of these rights are explored in more detail elsewhere in this document.
                    For completeness, your rights under GDPR are:</strong>
            </p>
            <ul>
                <li>The right to be informed</li>
                <li>The right of access</li>
                <li>The right to rectification</li>
                <li>The right to erasure</li>
                <li>The right to restrict processing</li>
                <li>The right to data portability</li>
                <li>The right to object</li>
                <li>Rights in relation to automated decision making and profiling.</li>
            </ul>
            <p>For more information about these rights, please see&nbsp; <a
                href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
                target="_blank">
                <u>the guidance provided by the ICO</u>
            </a>. If you have any questions or are unsure how to exercise your rights, please contact us at
                htsc.cloud@gmail.com. </p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.2 What information do you collect about me and why?</strong>
            </h3>
            <br/>
            <p>The information we collect is for the purpose of supporting your management of hosted homeservers through
                H.Work Matrix Services, or to support operational maintenance of the&nbsp;H.Work&nbsp;Matrix client. We
                do not profile homeserver Users or their data, but we might profile metadata pertaining to the
                configuration and management of hosted homeservers so that we can improve our products and services.</p>
            <br/>
            <p>
                <strong>2.2.1 Information you provide to us:</strong>
            </p>
            <br/>
            <p>
                <strong>We collect information about you when you input it to
                    the&nbsp;H.Work&nbsp;or&nbsp;H.Work&nbsp;Matrix Services apps or otherwise provide it directly to
                    us.</strong>
            </p>
            <p>
                <strong>H.Work Matrix Services (HMS) and H.Work Home Customer Account and Profile Information</strong>
            </p>
            <p>
                <strong>We collect information when you register for an account. This information is kept to a minimum
                    on purpose, and is restricted to:</strong>
            </p>
            <ul>
                <li>Email address</li>
                <li>Authentication Identifier; one of:</li>
                <li>Email address and password</li>
                <li>Twitter id</li>
                <li>Google id</li>
            </ul>
            <p>Your authentication identifier is used to authenticate your access to&nbsp;H.Work&nbsp;Matrix Services at
                https://ems.host and to uniquely identify you.</p>
            <p>You will be given the choice to set up 2-Factor Authentication to secure your account. For 2FA over SMS,
                we will require your phone number. This information will be shared with&nbsp; <a
                    href="https://www.twilio.com/" target="_blank">
                    <u>Twilio</u>
                </a>, a SMS service provider. </p>
            <p>H.Work Matrix Services (HMS) Customer and H.Work Home Account Transaction and Billing Information</p>
            <p>Paying for hosted homeserver services via HMS is handled entirely by our payment processor,&nbsp; <a
                href="https://stripe.com/en-gb/privacy" target="_blank">
                <u>Stripe</u>
            </a>. The processor stores your credit card information as well as your billing contact information in order
                to process your monthly or annual automatic renewals, or to allow you to upgrade or downgrade your
                subscription without re-entering a credit card number. </p>
            <p>We never have access to, nor store your full credit card information.</p>
            <p>The payment processor code we use also sets a cookie in your browser, to remember your info for future
                purchases. You can&nbsp; <a href="https://privacypolicies.com/blog/how-to-delete-cookies/"
                                            target="_blank">
                    <u>delete or block that cookie</u>
                </a>&nbsp;if you wish; our website will continue to work. </p>
            <p>We require you to enter your billing information. This data, as well as the last four digits of your
                credit card which is sent to us by our payment processor, is stored in our transaction database in order
                to maintain our financial records. This information appears on your invoice, which can be accessed by
                anyone who has been sent the url link to your invoice. We make the invoice links purposefully long and
                hard to guess for added security, and we prevent search engines from indexing them.</p>
            <p>The history of changes to the billing contact information on the invoice made by you or our team are
                logged and stored in our transaction database.</p>
            <p>The data we collect in our transaction database, including Personal Data, is not shared with third
                parties, except for the purposes of determining the validity of a payment. In this case we may share the
                name and email address associated with the purchase with the credit card holder, your company's
                accounting department, or with our payment processor when responding to a chargeback.</p>
            <p>We use a third-party,&nbsp; <a href="https://quaderno.io/policies/privacy/" target="_blank">
                <u>Quaderno</u>
            </a>, to help us with tax automation within our billing system. </p>
            <p>H,Work Home Account Setup</p>
            <p>When setting up an&nbsp;H.Work&nbsp;Home account via the setup wizard, we will temporarily access your
                account data, in order to migrate your existing Matrix account to your new&nbsp;H.Work&nbsp;Home
                account. This includes:</p>
            <ul>
                <li>List of rooms you have joined</li>
                <li>&nbsp;</li>
                <li>Power levels in those rooms</li>
                <li>Your avatar</li>
            </ul>
            <p>The setup wizard will also request your OpenID and access token, in order to retrieve your name and
                verified email address. This information will be temporarily sent to our backend, but will not be
                retained following your setup completion.</p>
            <p>If you do not have an email address verified by a trusted homeserver (such as Matrix.org), we will
                require you to add and verify a valid email address, for billing and host setup purposes.</p>
            <p>Lead generation and marketing information</p>
            <p>We might collect information about you through adverts placed in third-party platforms such as&nbsp; <a
                href="https://www.linkedin.com/" target="_blank">
                <u>LinkedIn</u>
            </a>,&nbsp; <a href="https://twitter.com/" target="_blank">
                <u>Twitter</u>
            </a>&nbsp;or&nbsp; <a href="https://www.google.com/" target="_blank">
                <u>Google</u>
            </a>. Whenever you click an advert on these platforms you may be asked to provide details such as: </p>
            <ul>
                <li>Email address</li>
                <li>Organisation of employment</li>
                <li>Job title</li>
            </ul>
            <p>Within each of these adverts you will be notified of the legal basis under which your data will be
                processed. This information will be stored on our marketing automation platform&nbsp; <a
                    href="https://outplayhq.com/" target="_blank">
                    <u>Outplay</u>
                </a>&nbsp;and our CRM systems&nbsp; <a href="https://www.salesforce.com/uk/?ir=1" target="_blank">
                    <u>Salesforce</u>
                </a>&nbsp;and&nbsp; <a href="https://www.pipedrive.com/" target="_blank">
                    <u>Pipedrive</u>
                </a>, and will be processed in accordance with this policy. We also use&nbsp; <a
                    href="https://www.hubspot.com/" target="_blank">
                    <u>Hubspot</u>
                </a>,&nbsp; <a href="https://matomo.org/" target="_blank">
                    <u>Matomo</u>
                </a>&nbsp;and&nbsp; <a href="https://posthog.com/" target="_blank">
                    <u>Posthog</u>
                </a>&nbsp;to manage our website analytics and marketing automation. </p>
            <p>
                <strong>2.2.2 Information we collect automatically as you use the service:</strong>
            </p>
            <br/>
            <p>
                <strong>Connection Information</strong>
            </p>
            <br/>
            <p>
                <strong>We log the IP addresses of everyone who accesses&nbsp;H.Work. This data is used in order to
                    mitigate abuse, debug operational issues, and monitor traffic patterns. Our logs are kept
                    for:</strong>
            </p>
            <ul>
                <li>30 days, for HMS Customer IP addresses;</li>
                <li>180 days, for&nbsp;H.Work&nbsp;chat app IP addresses;</li>
            </ul>
            <p>Usage Information</p>
            <p>We track usage data for&nbsp;H.Work&nbsp;hosting services. When you are signed in to your account we may
                track your usage of the site and associate that information with your account details. This data helps
                us understand how our users are using the application so that we can make improvements to the
                Service.</p>
            <p>Our analytics are powered by the Free and Open Source analytics platform&nbsp; <a
                href="https://matomo.org/" target="_blank">
                <u>Matomo</u>
            </a>, hosted entirely within our network. The servers which host this data are located in the EEA,
                specifically in London, United Kingdom. We don't share any analytics data with third parties. </p>
            <p>If you are using the&nbsp;H.Work&nbsp;chat app, you will be asked to opt-in to this anonymised data
                collection. Your use of the Service does not rely on your opt-in to this. When using any of
                the&nbsp;H.Work&nbsp;chat clients after opt-in, your visit to the service will be logged, alongside your
                device ID and agent. This allows us to understand usage patterns based on each platform. The mapping of
                this data is logged for 28 days - from this point on only aggregated data is kept, for operational and
                statistical purposes. For further details on our collection and usage of analytics data.</p>
            <p>When reporting errors we might collect some information to help us find a solution. This may include your
                IP address, hostname, full name and email address. This information is collated in the application
                monitoring platform&nbsp; <a href="https://sentry.io/welcome/" target="_blank">
                    <u>Sentry</u>
                </a>&nbsp;and is subject to strict retention policies. </p>
            <p>Location Information</p>
            <p>We may collect location data on you, if you choose to use the static or live location sharing features
                within the&nbsp;H.Work&nbsp;app. This includes your longitude, altitude and latitude data in order to
                accurately calculate your precise location.</p>
            <p>Location data is held within the room in which it is shared, so it will be encrypted in encrypted rooms
                and not encrypted in rooms where encryption is switched off. You will be shown a disclaimer during your
                first time using this feature, but please apply caution and consideration when sharing your personal
                data within the app.</p>
            <p>The&nbsp;H.Work&nbsp;clients use the third-party service&nbsp; <a href="https://www.maptiler.com/"
                                                                                 target="_blank">
                <u>MapTiler</u>
            </a>&nbsp;to provide the images used to display maps. </p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.3. Sharing Data in Compliance with Enforcement Requests and Applicable Laws; Enforcement of
                    Our Rights</strong>
            </h3>
            <br/>
            <p>In exceptional circumstances, we may share information about you with a third party if we believe that
                sharing is reasonably necessary to</p>
            <ul>
                <li>comply with any applicable law, regulation, legal process or governmental request,</li>
                <li>protect the security or integrity of our products and services (e.g. for a security audit),</li>
                <li>protect&nbsp;H.Work&nbsp;and our users from harm or illegal activities, or</li>
                <li>respond to an emergency which we believe in good faith requires us to disclose information to assist
                    in preventing the serious bodily harm of any person.
                </li>
            </ul>
            <br/>
            <h3>
                <strong>2.4. How do you handle passwords?</strong>
            </h3>
            <br/>
            <p>We never store password data in plain text; instead they are stored hashed (with at least 12 rounds of
                bcrypt, including both a salt and a server-side pepper secret). Passwords sent to the server are
                encrypted using SSL.</p>
            <p>It is your sole responsibility to keep your user name, password and other sensitive information
                confidential. Actions taken using your credentials shall be deemed to be actions taken by you, with all
                consequences including service termination, civil and criminal penalties.</p>
            <p>If you become aware of any unauthorised use of your account or any other breach of security, you must
                notify&nbsp;H.Work&nbsp;immediately by sending an email to htsc.cloud@gmail.com. Users should manage
                good password hygiene (e.g. using a password manager) and change their password if they believe their
                account is compromised.</p>
            <p>If you forget your password (and you have registered an email address) you can use the password reset
                facility to reset it.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.5. Our commitment to Children’s Privacy</strong>
            </h3>
            <br/>
            <p>We never knowingly collect or maintain information in&nbsp;H.Work, through any of the Services provided,
                from those we know are under 16, and no part of&nbsp;H.Work&nbsp;is structured to attract anyone under
                16. If you are under 16, please do not use the Service.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.6. How can I access or correct my information?</strong>
            </h3>
            <p>If you are a customer of&nbsp;H.Work&nbsp;Matrix Services (HMS) you can access all personal information
                that we collect by using the account management interface at&nbsp; <a href="https://element.io/"
                                                                                      target="_blank">
                    <u>https://</u>
                </a>chat.htigroup.vn. </p>
            <p>If you are a user of the&nbsp;H.Work&nbsp;chat app you can request a copy of your data by emailing
                htsc.cloud@gmail.com. We are working on a solution which will allow you to download the data
                automatically.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.7. Who can see my homeserver configuration?</strong>
            </h3>
            <p>Data stored in&nbsp;H.Work&nbsp;is accessible by the Customer's account and by&nbsp;H.Work&nbsp;engineers
                (employees and contractors) under the conditions outlined below.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.8. What are the Guidelines H.Work follows when accessing my data?</strong>
            </h3>
            <ul>
                <li>We restrict who at&nbsp;H.Work&nbsp;(employees and contractors) can
                    access&nbsp;H.Work&nbsp;non-encrypted data to roles which require access in order to maintain the
                    health of the&nbsp;H.Work&nbsp;apps and services.
                </li>
                <li>We never share what we see with other users or the general public.</li>
            </ul>
            <br/>
            <h3>
                <strong>2.9. Who else has access to my Data?</strong>
            </h3>
            <p>We host the&nbsp;H.Work&nbsp;Matrix Services on Amazon Web Services (AWS), specifically:</p>
            <ul>
                <li>Our admin server is hosted in an AWS data centre in Amsterdam;</li>
                <li>Our deployment server is hosted in an AWS data centre in Stockholm;</li>
                <li>Customer deployments have the option to select the geographical location which is the most
                    convenient for them;
                </li>
            </ul>
            <p>We also host the Gitter.im app on AWS, in a datacenter based in the East of the US.</p>
            <p>Amazon employees may have access to some of this data. Here's&nbsp; <a
                href="https://aws.amazon.com/privacy/" target="_blank">
                <u>Amazon's privacy policy</u>
            </a>. Amazon controls physical access to their locations. </p>
            <p>We use Cloudflare to mitigate the risk of DDoS attacks. Here's&nbsp; <a
                href="https://www.cloudflare.com/privacypolicy/" target="_blank">
                <u>CloudFlare's privacy policy</u>
            </a>. </p>
            <p>Physical access to our offices and locations use typical physical access restrictions.</p>
            <p>We use secure private keys when accessing servers via SSH, and protect our AWS console passwords locally
                with a password management tool.</p>
            <p>Nobody at&nbsp;H.Work, or any of our processors, is able to access encrypted data.</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.10. How is my Data protected from another user’s Data?</strong>
            </h3>
            <p>All of the&nbsp;H.Work&nbsp;user data resides within the same dedicated cluster. We use software best
                practices to guarantee that only the Customer can access it. In other words, we segment User data via
                software. We do our best and are very confident we're doing a good job at it, but, like every other
                service that hosts User data on the same database, we cannot guarantee that it is immune to a
                sophisticated attack.‍</p>
            <p>
                <br/>
            </p>
            <h3>
                <strong>2.12. What should I do if I find a security vulnerability in the service?</strong>
            </h3>
            <p>If you have discovered a security concern, please email us at <a href="mailto:htsc.cloud@gmail.com"
                                                                                target="_blank">htsc.cloud@gmail.com</a> We'll
                work with you to make sure that we understand the scope of the issue, and that we fully address your
                concern. Information security is our highest priority, and we work to address any issues that arise as
                quickly as possible. </p>
            <p>Please act in good faith towards our users' privacy and data during your disclosure. White hat security
                researchers are always appreciated.</p>
            <br/>
            <h2>
                <strong>3. Making a Complaint</strong>
            </h2>
            <br/>
            <p>We try to meet the highest standards when collecting and using personal information. For this reason, we
                take any complaints we receive about this very seriously. We encourage people to bring it to our
                attention at htsc.cloud@gmail.com if they think that our collection or use of information is unfair,
                misleading or inappropriate. We would also welcome any suggestions for improving our procedures.</p>
            <p>&nbsp;</p>
            <p>If you want to make a complaint about the way we have processed your personal information to the
                supervisory authority, you can contact the ICO (the statutory body which oversees data protection law)
                at&nbsp; <a href="https://www.ico.org.uk/concerns" target="_blank">
                    <u>https://www.ico.org.uk/concerns</u>
                </a>. </p>
        </div>
    );
};

export default PrivacyPolicy;