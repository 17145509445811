import React from 'react';
import './PageSection.css';
import post_image from "../../images/post_image.png";
const PageSection = () => {
    return (
        <section className="container">
            <div className="download-section">
                <div className="text-content">
                    <h1>Tải Xuống<span className="dot">.</span></h1>
                    <p>Dễ dàng cài đặt, tính bảo mật cao. Tải ngay ứng dụng chat nội bộ H.Work tại đây.</p>
                </div>
                <div className="image-content">
                    <img src={post_image} alt="H.Work App Download"/>
                </div>
            </div>
        </section>
    );
};

export default PageSection;