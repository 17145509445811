import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer container">
            {/*<div className="footer-left">*/}
            {/*    <img src={logo} alt="HTI Logo" className="logo"/>*/}
            {/*</div>*/}
            <div className="footer-center">
                <p>Copyright © 2024 H.Work • <a href="#">Legal</a> • <a href="#">Privacy</a></p>
            </div>
            {/*<div className="footer-right">*/}
            {/*    <a href="#"><img src="linkedin-icon.png" alt="LinkedIn"/></a>*/}
            {/*    <a href="#"><img src="facebook-icon.png" alt="Facebook"/></a>*/}
            {/*    <a href="#"><img src="youtube-icon.png" alt="YouTube"/></a>*/}
            {/*</div>*/}
        </footer>
    );
};

export default Footer;