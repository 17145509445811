import React from 'react';
import './GuildPageUpdate.css';
import Guide1 from '../../images/hd1.png'
import Guide2 from '../../images/hd2.png'
import Guide3 from '../../images/hd3.png'
import Guide4 from '../../images/hd4.png'
import QuitUpdate from '../../images/quit_update.png'


const GuidePageUpdate = () => {
    return (
        <div className="guide-page-update">
            <h1>
                <strong>I. Hướng dẫn Cài đặt H.Work.</strong>
            </h1>
            <br />
            <p>
                <strong>Truy cập vào đường dẫn:</strong> <a href="https://hwork.htigroup.vn/#download-section" target="_blank" rel="noopener noreferrer">https://hwork.htigroup.vn/#download-section</a>. Khi truy cập vào trang sẽ thấy một khu vực có tên "Tải Xuống" Tại đây bạn có thể tải về phiên bản phù hợp của H.Work cho hệ điều hành mà bạn đang sử dụng. Bấm vào tải xuống và làm theo hướng dẫn trên màn hình.
            </p>
            <p><br /></p>
            <p><br /></p>
            <p>
                <img src={Guide1} alt="Hướng dẫn cài đặt 1" height="291" width="631" />
            </p>
            <p><br /></p>
            <p>
                <img src={Guide2} alt="Hướng dẫn cài đặt 2" height="240" width="459" />
            </p>
            <p><br /></p>
            <p>
                <img src={Guide3} alt="Hướng dẫn cài đặt 3" height="306" width="507" />
            </p>
            <p><br /></p>
            <p>
                <img src={Guide4} alt="Hướng dẫn cài đặt 4" height="333" width="460" />
            </p>
            <p>&nbsp;</p>
            <p>Bấm vào file và cài đặt ứng dụng.</p>
            <br />
            <br />
            <br />
            <h1>
                <strong>II. Hướng dẫn cập nhật phiên bản Desktop mới.</strong>
            </h1>
            <br />
            <h2>
                <strong>1. Tắt toàn bộ ứng dụng chạy nền H.Work đang hoạt động trước khi tiến hành cài bản cập nhật mới.</strong>
            </h2>
            <br />
            <p>Đảm bảo rằng tất cả các phiên bản H.Work đang hoạt động trên máy tính đều được đóng hoàn toàn trước khi bắt đầu quá trình cập nhật.</p>
            <p>&nbsp;</p>
            <p>
                <img src={QuitUpdate} alt="Hướng dẫn cập nhật" height="222" width="609" />
            </p>
            <br />
            <h2>
                <strong>2. Tải và cài đặt phiên bản H.Work tại link sau và bấm vào cài đặt ứng dụng sẽ tự động cài đè phiên bản mới:</strong>
            </h2>
            <br />
            <p>
                <a href="https://hwork.htigroup.vn/#download-section" target="_blank" rel="noopener noreferrer">
                    <em>
                        <u>Download H.Work</u>
                    </em>
                </a>
            </p>
            <p>&nbsp;</p>
            <h1>&nbsp;</h1>
        </div>
    );
};

export default GuidePageUpdate;
