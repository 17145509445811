import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './GuildDetail.css'; // Import the CSS file

const GuildDetail = () => {
  return (
    <div className="guild-detail">
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
        <div className="pdf-viewer">
          <Viewer fileUrl="/pdf/HDSD_H.Work.pdf" />
        </div>
      </Worker>
    </div>
  );
};

export default GuildDetail;